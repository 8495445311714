<!--
 * @Author: wxy
 * @Describe: 
 * @LastEditors: wxy
 * @LastEditTime: 2021-04-13 21:26:25
-->
<template>
  <a v-if="isExternalLink" v-bind="$attrs" :href="to" target="_blank">
    <slot />
  </a>
  <router-link v-else v-bind="$props" custom v-slot="{ isActive, href, navigate }">
    <a
      v-if="tag === 'a'"
      v-bind="$attrs"
      :href="href"
      @click="navigate"
      :class="isActive ? activeClass : inactiveClass"
    >
      <slot />
    </a>
    <span
      class="link-span"
      v-if="tag === 'span'"
      v-bind="$attrs"
      :href="href"
      @click="navigate"
      :class="isActive ? activeClass : inactiveClass"
    >
      <slot />
    </span>
  </router-link>
</template>

<script>
import { RouterLink } from "vue-router";

export default {
  props: {
    // 如果使用 TypeScript，请添加 @ts-ignore
    ...RouterLink.props,
    inactiveClass: String,
    tag: {
      type: String,
      default: "a",
    },
  },

  computed: {
    isExternalLink() {
      return typeof this.to === "string" && this.to.startsWith("http");
    },
  },
};
</script>

<style lang="less" scoped>
  .link-span{
    cursor: pointer;
  }
</style>
